<template>
	<div>
		<!-- pc版 -->
		<header class="container-fluid header-pc d-none d-md-block">
			<div class="container">
				<div class="left">
					<router-link to="/" class="logo">
						<img :src="'http://guanli.jjyholdingsgroup.com/prod-api'+info.logo" class="d-none d-sm-block"
							alt="" />
					</router-link>

					<!-- <a href="http://www.jiajiayue.com.cn/index.htm" target="_blank" class="logo">
						<img :src="'http://guanli.jjyholdingsgroup.com/prod-api'+info.logo" class="d-none d-sm-block"
							alt="" />
					</a> -->

					<ul class="list menu">
						<li class="hasSubMenu" :class="current==0?'on':''">
						<li v-for="(itema,index) in list">
							<router-link :to="itema.url">{{itema.name}}</router-link>

							<div class="subMenu">
								<div class="con">
						<li v-for="(item,index) in itema.sonList">
							<router-link :to="item.url">{{item.name}}</router-link>
						</li>
				</div>
			</div>
			</li>
			</li>
			</ul>
	</div>
	<div class="right">
		<!-- <div to="" class="btn" @click="goToPage()">
			集团官网
		</div> -->
		<a href="http://www.jiajiayue.com.cn/index.htm" target="_blank"><button class="btn">集团官网</button></a>
	</div>
	</div>

	</header>
	<!-- 手机版 -->
	<header class="header-phone d-md-none">
		<router-link to="" class="logo">
			<img :src="'http://guanli.jjyholdingsgroup.com/prod-api'+info.logo" class="d-none d-sm-block" alt="" />
			<!-- <img src="@/assets/images/logo.png" alt=""> -->
		</router-link>
		<div to="" class="btn-menu" :class="showMenu?'on':''" @click="showMenu = !showMenu"></div>
	</header>
	<div class="alert-menu" v-if="showMenu">
		<div class="over-close" @click="showMenu = false"></div>
		<div class="box">
			<ul class="list menu">
				<li class="hasSubMenu" :class="current==0?'on':''">
				<li v-for="(itema,index) in list">
					<router-link :to="itema.url">{{itema.name}}</router-link>

					<div class="subMenu">
						<div class="con">
				<li v-for="(item,index) in itema.sonList">
					<router-link :to="item.url">{{item.name}}</router-link>
				</li>
				<!--									<router-link to="">企业简介</router-link>-->
				<!--									<router-link to="">业态信息</router-link>-->
				<!--									<router-link to="">人才招募</router-link>-->
				<!--									<router-link to="">招商</router-link>-->
				<!--									<router-link to="">关于我们</router-link>-->
		</div>
	</div>
	</li>
	</li>
	<li>
		<router-link to="" class="btn">
			{{info.phone}} <img src="@/assets/images/call-white.png" class="uc-icon16" alt="" />
		</router-link>
	</li>
	</ul>
	</div>
	</div>
	</div>
</template>
<script>
	import {
		getInfo,
		getNavigation
	} from "@/api/userMG";

	export default {
		name: '',
		components: {},
		props: {
			current: {
				type: Number,
				default: null
			},
		},
		data() {
			return {
				showMenu: false,
				showMore1: false,
				showMore2: false,
				showMore3: false,
				list: [],
				info: {},
			}
		},
		mounted() {
			this.toGetNavigation()
			getInfo().then(res => {
				this.info = res.data
			})
		},
		methods: {
			goToPage(url) {
				window.location.href = 'http://www.jiajiayue.com.cn/index.htm';
			},
			toGetNavigation() {
				getNavigation().then(res => {
					this.list = res.data
				})
			}
		},
	}
</script>