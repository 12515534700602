<template>
	<div>
		<footer class="container-fluid">
			<div class="ft1">
				<div class="container">
					<div class="row">
						<div class="col-24 col-sm-10 col-md-8">
							<router-link to="/" class="logo">
								<img :src="'http://guanli.jjyholdingsgroup.com/prod-api'+info.logoTwo"
									class="d-none d-sm-block" />
							</router-link>
							<!-- <a href="http://www.jiajiayue.com.cn/index.htm" target="_blank" class="logo">
								<img :src="'http://guanli.jjyholdingsgroup.com/prod-api'+info.logoTwo"
									class="d-none d-sm-block" alt="" />
							</a> -->
						</div>
						<div class="col-24 col-sm-14 col-md-16">
							<!-- <div class="call">
								<img src="@/assets/images/call-white.png" class="uc-icon32" alt="" />
								官方服务热线 &nbsp;{{info.phone}}
							</div> -->
						</div>
					</div>
				</div>
			</div>
			<div class="ft2">
				<div class="container">
					<ul class="row list">
						<li class="col-6 col-sm-4 col-xl-3" v-for="(item,index) in list" :key="index">
							<h3>{{item.name}}</h3>
							<div class="con" v-for="(items,indexs) in item.sonList" :key="indexs">
								<router-link :to="items.url">{{items.name}}</router-link>
							</div>
						</li>
						<!-- <li class="col-6 col-sm-4 col-xl-3">
							<h3>首页</h3>
							<div class="con">
								<router-link to="">企业简介</router-link>
								<router-link to="">企业荣誉</router-link>
							</div>
						</li>
						<li class="col-6 col-sm-4 col-xl-4">
							<h3>资讯</h3>
							<div class="con">
								<router-link to="">九龙城事业部</router-link>
								<router-link to="">酒店事业部</router-link>
								<router-link to="">咖啡事业部</router-link>
								<router-link to="">地产事业部</router-link>
								<router-link to="">信息披露</router-link>
							</div>
						</li>
						<li class="col-6 col-sm-4 col-xl-3">
							<h3>人才</h3>
							<div class="con">
								<router-link to="">人才招募</router-link>
								<router-link to="">职业发展</router-link>
							</div>
						</li>
						<li class="col-6 col-sm-4 col-xl-3">
							<h3>招商合作</h3>
							<div class="con">
								<router-link to="">招商</router-link>
							</div>
						</li>
						<li class="col-6 col-sm-4 col-xl-3">
							<h3>联系我们</h3>
							<div class="con">
								<router-link to="">关于我们</router-link>
							</div>
						</li> -->
						<li class="col-18 col-sm-4 col-xl-6 offset-xl-2">
							<div class="contact">
								<!-- <div class="item">
									<img src="@/assets/images/footer-icon-1.png" alt="" class="uc-icon16">
									<div class="r">邮箱：{{info.email}}</div>
								</div> -->
								<div class="item">
									<img src="@/assets/images/footer-icon-2.png" alt="" class="uc-icon16">
									<div class="r">地址：{{info.address}}</div>
								</div>
								<div class="item">
									<img src="@/assets/images/footer-icon-3.png" alt="" class="uc-icon16">
									<div class="r">联系电话：{{info.phone}}</div>
								</div>
								<div class="item">
									<img src="@/assets/images/footer-icon-4.png" alt="" class="uc-icon16">
									<div class="r">邮编：{{info.postcode}}</div>
								</div>
							</div>
						</li>
					</ul>
				</div>
			</div>
			<div class="ft3">
				<div class="container">
					<div class="row">
						<div class="col-24">
							<div class="cpt">
								版权所有2024家家悦控股集团股份有限公司 <a href="">鲁ICP备11022826号</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</footer>
		<span class="g-goTop" @click="scroll"><img src="@/assets/images/icon-goTop.png" class="uc-icon28"
				alt="">返回顶部</span>
	</div>
</template>
<script>
	import {
		getInfo,
		getNavigation
	} from "@/api/userMG";

	export default {
		name: '',
		components: {},
		data() {
			return {
				info: {},
				list: [],
			}
		},
		mounted() {
			this.toGetNavigation()
			getInfo().then(res => {
				console.log("getInfogetInfogetInfo", res)
				this.info = res.data
			})
		},
		methods: {
			toGetNavigation() {
				getNavigation().then(res => {
					this.list = res.data
				})
			},
			scroll(pos) {
				window.scrollTo({
					top: 0,
					behavior: "smooth"
				});
			},
		},
	}
</script>