<template>
	<div class="uc-home-1">
		<swiper :options="swiperOption" ref="swiper">
			<swiper-slide v-for="(item,index) in list" :key="index">
				<router-link to="">
					<img :src="'http://guanli.jjyholdingsgroup.com/prod-api'+item.image" class="d-none d-sm-block" alt="" />
				</router-link>
			</swiper-slide>
			<div class="swiper-pagination" slot="pagination"></div>
		</swiper>
	</div>
</template>
<script>
	import {
		Swiper,
		SwiperSlide
	} from 'vue-awesome-swiper'
	import 'swiper/css/swiper.css'
	import {
		getImages
	} from "@/api/userMG";
	export default {
		components: {
			Swiper,
			SwiperSlide
		},
		data() {
			return {
				swiperOption: {
					loop: true,
					observeParents: true,
					observer: true,
					pagination: {
						el: '.uc-home-1 .swiper-container .swiper-pagination',
						clickable: true,
					},
					autoplay: {
						delay: 2500,
						disableOnInteraction: false,
					},
				},
				list: [],
			};
		},
		mounted() {
			getImages().then(res => {
				this.list = res.data
			})
		}
	};
</script>
<style>
	.swiper-pagination-bullet {
		background-color: #fff;
		opacity: .8;
	}

	.swiper-pagination-bullet-active {
		background-color: #e60012;
		width: 2em;
		border-radius: 1.15em;
		opacity: 1;
	}
</style>