import axios from 'axios';
import {
	req
} from './axiosFun';

export const apiBaseUrl = 'http://guanli.jjyholdingsgroup.com/prod-api'

export const apiImageBaseUrl = 'http://guanli.jjyholdingsgroup.com/prod-api'


const uploadPath = apiBaseUrl + '/utils/image'
export {
	uploadPath
}

const uploadPaths = apiBaseUrl + '/utils/video'
export {
	uploadPaths
}

// 获取导航
export const getNavigation = () => {
	return req("get", apiBaseUrl + "/system/jjy/getNavigation")
};

// 获取网站数据
export const getInfo = () => {
	return req("get", apiBaseUrl + "/system/jjy/getInfo")
};

// 获取轮播图
export const getImages = () => {
	return req("get", apiBaseUrl + "/system/jjy/images")
};

// 获取新闻
export const getNews = (params) => {
	return req("get", apiBaseUrl + "/system/jjy/getNews?pageNum=" + params.pageNum + "&pageSize=" + params.pageSize)
};

// 获取招聘列表
export const getJob = (params) => {
	return req("get", apiBaseUrl + "/system/jjy/getJob?pageNum=" + params.pageNum + "&pageSize=" + params.pageSize)
};

// 获取事业部列表
export const getCarousel = (params) => {
	return req("get", apiBaseUrl + "/system/jjy/getCarousel?pageNum=" + params.pageNum + "&pageSize=" + params
		.pageSize)
}

// 获取荣誉列表
export const getCompanyList = () => {
	return req("get", apiBaseUrl + "/system/images/list")
}

// 获取招聘详情
export const getJobDetail = (params) => {
	return req("get", apiBaseUrl + "/system/jjy/job/" + params.id)
}

// 获取新闻详情
export const getNewDetail = (params) => {
	return req("get", apiBaseUrl + "/system/jjy/news/" + params.id)
}

// 获取事业部详情
export const getCarouselDetail = (params) => {
	return req("get", apiBaseUrl + "/system/jjy/carousel/" + params.id)
}

// 获取联系我们
export const getUs = () => {
	return req("get", apiBaseUrl + "/system/jjy/us")
}

// 获取招商信息
export const getInvestment = () => {
	return req("get", apiBaseUrl + "/system/jjy/investment")
}

// 获取公司简介
export const getIntro = () => {
	return req("get", apiBaseUrl + "/system/jjy/intro")
}

// 获取荣誉详情
export const getIntroDetail = (params) => {
	return req("get", apiBaseUrl + "/system/jjy/images/" + params.id)
}

// 获取职业发展
export const getDevelop = () => {
	return req("get", apiBaseUrl + "/system/jjy/develop")
}

// 获取事业部下级
export const getCarouselLevel = (params) => {
	return req("get", apiBaseUrl + "/system/jjy/getCarouselSon/" + params.id)
}

// 获取事业部下级详情
export const getCarouselLevelDetail = (params) => {
	return req("get", apiBaseUrl + "/system/jjy/getCarouselInfo/" + params.id)
}

// 获取企业荣誉轮播图
export const getFirmImagesList = (params) => {
	return req("get", apiBaseUrl + "/system/jjy/firmImagesList")
}

// 获取行业信息
export const getIndustryInfo = (params) => {
	return req("get", apiBaseUrl + "/system/jjy/industryInfo")
}