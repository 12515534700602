import axios from 'axios';

// 登录请求方法
// 通用公用方法
const req = (method, url, params) => {
	// let userToken = localStorage.getItem('logintoken');
	// 判断token是否存在
	// if (!userToken) {
	// 	this.$message.error('身份已过期，请求失败')
	// 	return false;
	// }
	return axios({
		method: method,
		url: url,
		headers: {
			// 'Accept': 'application/json',
			'Content-Type': 'application/json;charset=utf-8',
			// 'Access-Control-Allow-Origin': '*',
			// 'Access-Contro1-Allow-origin'
			// 'Content-Type': 'application/x-www-form-urlencoded',
			// Authorization: localStorage.getItem('tokenHead') + ' ' + localStorage.getItem('logintoken')

		},
		data: params,
		traditional: true,
		transformRequest: [(params => {
			return JSON.stringify(params)
		})]
		// transformRequest: [
		// 	function(data) {
		// 		let ret = ''
		// 		for (let it in data) {
		// 			ret +=
		// 				encodeURIComponent(it) +
		// 				'=' +
		// 				encodeURIComponent(data[it]) +
		// 				'&'
		// 		}
		// 		return ret
		// 	}
		// ]
	}).then(res => res.data);
};

export {
	req
}
