import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
		path: '/',
		name: 'home',
		component: () => import('../views/home.vue')
	},
	// {
	//   path: '/home',
	//   name: 'home',
	//   component: () => import('../views/home.vue')
	// },
	{
		path: '/jobs',
		name: 'jobs',
		component: () => import('../views/jobs.vue')
	},
	{
		path: '/news',
		name: 'news',
		component: () => import('../views/news.vue')
	},
	{
		path: '/information',
		name: 'information',
		component: () => import('../views/information.vue')
	},
	{
		path: '/company',
		name: 'company',
		component: () => import('../views/company.vue')
	},
	{
		path: '/companys',
		name: 'companys',
		component: () => import('../views/companys.vue')
	},
	{
		path: '/newDetail',
		name: 'newDetail',
		component: () => import('../views/newDetail.vue')
	},
	{
		path: '/level',
		name: 'level',
		component: () => import('../views/level.vue')
	},
	{
		path: '/infoDetail',
		name: 'infoDetail',
		component: () => import('../views/infoDetail.vue')
	},
	{
		path: '/jobDetail',
		name: 'jobDetail',
		component: () => import('../views/jobDetail.vue')
	},
	{
		path: '/zhiYe',
		name: 'zhiYe',
		component: () => import('../views/zhiYe.vue')
	},
	{
		path: '/attract',
		name: 'attract',
		component: () => import('../views/attract.vue')
	},
	{
		path: '/industry',
		name: 'industry',
		component: () => import('../views/industry.vue')
	},
	{
		path: '/companys_detail',
		name: 'companys_detail',
		component: () => import('../views/companys_detail.vue')
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

export default router